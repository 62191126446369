export const BLPsvg: React.FC<{
  className?: string;
  svgProps?: React.SVGProps<SVGSVGElement>;
}> = ({ className, svgProps }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...svgProps}
    >
      <path
        d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"
        fill="url(#paint0_linear_1_43)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.03884 11.577C5.65053 11.0157 6.05242 10.2493 6.73521 10.2493C7.31357 10.2493 7.88046 10.2493 8.45506 10.2493C8.45506 9.6481 8.45506 9.06815 8.45506 8.45278C8.31555 8.45278 8.1969 8.45278 8.07132 8.45278C6.79455 8.45278 5.51076 8.44572 4.23395 8.45989C3.96884 8.45989 3.80837 8.37498 3.66883 8.15574C3.50565 7.898 3.33423 7.64652 3.15566 7.38997C2.96053 7.1096 3.15958 6.72701 3.50129 6.72701C5.4686 6.72701 7.40694 6.72701 9.35278 6.72701C9.82029 6.72701 10.1993 7.10587 10.1993 7.57316C10.1993 9.04217 10.1993 10.5068 10.1993 11.9963C9.65513 11.9963 9.10394 11.9963 8.44807 11.9963C9.33416 13.2836 10.1784 14.5001 11.0505 15.7732C11.9157 14.5284 12.7529 13.3118 13.639 12.0317C13.039 12.0317 12.4947 12.0317 11.9226 12.0317C11.9226 11.7234 11.9226 11.4263 11.9226 11.1308C11.9226 10.6635 12.3016 10.2847 12.7692 10.2847C13.6232 10.2847 14.4788 10.2847 15.3465 10.2847C16.029 10.2847 16.4309 11.0504 16.043 11.6117C14.7274 13.5155 13.4198 15.4077 12.1016 17.32C11.5972 18.0517 10.5161 18.0529 10.0106 17.3219C8.68794 15.4088 7.37169 13.5039 6.03884 11.577Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8452 8.30555C11.8452 8.0597 11.8452 7.81664 11.8452 7.57316C11.8452 7.10587 12.2242 6.72701 12.6917 6.72701C14.6343 6.72701 16.5732 6.72701 18.5413 6.72701C18.8892 6.72701 19.0894 7.1239 18.8827 7.40359C18.6703 7.69112 18.4609 7.97297 18.2471 8.25271C18.205 8.31215 18.0858 8.33859 18.0017 8.33859C15.9963 8.34519 13.9908 8.33859 11.9854 8.33859C11.9504 8.33859 11.9153 8.32539 11.8452 8.30555Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_43"
          x1="11.0324"
          y1="7.66886"
          x2="11"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0DB6D2" />
          <stop offset="1" stopColor="#4326F2" />
        </linearGradient>
      </defs>
    </svg>
  );
};
