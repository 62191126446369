import React from 'react';
import Drawer from '@Views/Common/V2-Drawer';

export const LeaderBoardDrawers = () => {
  return (
    <Drawer open={false}>
      <></>
    </Drawer>
  );
};
