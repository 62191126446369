import LeaderboardTropy from 'public/LeaderBoard/Trophy';
import { TOTALWINNERS } from '../Incentivised';

export default function Trophy({
  row,
  isUser,
  currentRank,
  nftWinners = 0,
}: {
  row: number;
  isUser: boolean;
  currentRank: number;
  nftWinners?: number;
}) {
  if (currentRank <= TOTALWINNERS || row === 0) {
    if (isUser && row === 0 && currentRank > TOTALWINNERS)
      return (
        <svg
          width={17}
          height={16}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mx-2 scale-110"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.865.715a7.671 7.671 0 0 0-4.37 1.761c-.234.194-.782.742-.97.97a7.645 7.645 0 0 0 .814 10.64 7.64 7.64 0 0 0 11.09-1.038 7.622 7.622 0 0 0 1.465-3.2 7.641 7.641 0 0 0-1.764-6.557c-.175-.2-.603-.622-.795-.783A7.64 7.64 0 0 0 9.176.738 11.576 11.576 0 0 0 7.865.715Zm.932 3.323c.62.138 1.138.496 1.476 1.017.6.929.453 2.117-.36 2.89-.32.305-.745.52-1.19.602a2.924 2.924 0 0 1-.866-.007 2.279 2.279 0 0 1-1.203-.65c-.35-.35-.552-.73-.64-1.204-.043-.227-.04-.629.007-.846.094-.442.304-.833.619-1.151a2.335 2.335 0 0 1 1.303-.674c.179-.031.67-.018.854.023Zm.917 5.222c.651.11 1.202.39 1.648.837.512.513.793 1.134.843 1.866.02.284.003.481-.05.596-.136.29-.56.467-1.293.536-.358.034-2.403.053-3.626.034-1.208-.02-1.366-.027-1.692-.081-.49-.082-.783-.233-.901-.466-.116-.229-.076-.882.084-1.36.2-.599.607-1.13 1.139-1.486.42-.28.893-.45 1.39-.495.082-.008.633-.013 1.225-.01.925.003 1.1.007 1.233.03Z"
            fill="#808191"
          />
        </svg>
      );
    if (currentRank <= TOTALWINNERS)
      return (
        <>
          <LeaderboardTropy />
          {currentRank <= nftWinners && (
            <img
              src="https://a.slack-edge.com/production-standard-emoji-assets/14.0/google-medium/1f419@2x.png"
              width={23}
              height={23}
            />
          )}
        </>
      );
    if (isUser) {
      return (
        <svg
          width={17}
          height={16}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mx-2 scale-110"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.865.715a7.671 7.671 0 0 0-4.37 1.761c-.234.194-.782.742-.97.97a7.645 7.645 0 0 0 .814 10.64 7.64 7.64 0 0 0 11.09-1.038 7.622 7.622 0 0 0 1.465-3.2 7.641 7.641 0 0 0-1.764-6.557c-.175-.2-.603-.622-.795-.783A7.64 7.64 0 0 0 9.176.738 11.576 11.576 0 0 0 7.865.715Zm.932 3.323c.62.138 1.138.496 1.476 1.017.6.929.453 2.117-.36 2.89-.32.305-.745.52-1.19.602a2.924 2.924 0 0 1-.866-.007 2.279 2.279 0 0 1-1.203-.65c-.35-.35-.552-.73-.64-1.204-.043-.227-.04-.629.007-.846.094-.442.304-.833.619-1.151a2.335 2.335 0 0 1 1.303-.674c.179-.031.67-.018.854.023Zm.917 5.222c.651.11 1.202.39 1.648.837.512.513.793 1.134.843 1.866.02.284.003.481-.05.596-.136.29-.56.467-1.293.536-.358.034-2.403.053-3.626.034-1.208-.02-1.366-.027-1.692-.081-.49-.082-.783-.233-.901-.466-.116-.229-.076-.882.084-1.36.2-.599.607-1.13 1.139-1.486.42-.28.893-.45 1.39-.495.082-.008.633-.013 1.225-.01.925.003 1.1.007 1.233.03Z"
            fill="#808191"
          />
        </svg>
      );
    }
  }
  return <></>;
}
